import moment from 'moment'


jQuery(document).ready(function ($) {

  // TOUR
  // Tourdate Import
  $.ajax({
    url: `https://rest.bandsintown.com/artists/id_15563236/events?app_id=45PRESS_damiano_david`,
    method: 'GET',
    dataType: 'json',
    error: () => alert('Error fetching events!'),
    success: data => {
      const eventsContainer = $('#tour-dates');

      if (data.length) {
        // Generate HTML for each event and append to the events container
        const html = data.map(event => generateEventHtml(event)).join('');
        eventsContainer.html(html);

        // Hide the "toggle dates" button if fewer than 9 events are available
        if (data.length < 9) {
          $("#toggle-dates").hide();
        }
      } else {
        // Display message if no events are found
        eventsContainer.html('<span class="no-events">Check back soon for new shows!</span>');
      }
    }
  });

  // Helper function to generate HTML for each event
  function generateEventHtml(event) {
    const eventDate = moment(event.datetime).format('MMM DD');
    const venueName = event.venue.name;
    const venueRegion = event.venue.region !== null && event.venue.region !== '' ? event.venue.region : event.venue.country;
    const venueLocation = event.venue.city + ', ' + venueRegion;


    const offersHtml = event.offers.map(offer =>
      `<a href="${offer.url}" target="_blank" class="link btn">${offer.type}</a>`
    ).join('');

    return `
    <div class="event-group">
      <div class="event-date">${eventDate}</div>
      <div class="event-venue">${venueName}</div>
      <div class="event-location">${venueLocation}</div>
      <div class="event-links">${offersHtml}</div>
    </div>
  `;
  }



  // NEWSLETTER
  $("#damiano-david-website-signup").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        // Do things on successful submission.
        alert("Submitted!");
      },
      error: function (err) {
        // Do things when submission has an error.
        alert("An error has occured!");
      }
    });
  });

  $("#damiano-david-website-signup-2").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        // Do things on successful submission.
        alert("Submitted!");
      },
      error: function (err) {
        // Do things when submission has an error.
        alert("An error has occured!");
      }
    });
  });

  $('body').on('change', '.mailing-list-id', function () {
    $("#ts-for-" + $(this).attr('id')).prop("checked", $(this).is(':checked'));
  });
});